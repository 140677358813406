<template>
  <b-overlay
    :show="!cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-card
      aria-hidden="true"
      class="mb-0"
    >
      <b-form @submit.prevent="$emit('processForm', establecimiento)">
        <!-- Establecimiento Info: Input Fields -->
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="BellIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Notificaciones
            </h4>
          </div>
        </b-col>
        <b-row>

          <!-- Field: Dependencia -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Dependencia"
              label-for="dependencia"
            >
              <!-- v-model="establecimiento.dependencia" -->
              <v-select
                placeholder="Selecciona el o los roles"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rolesOption"
                label="text"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <colLinea />

        <!-- Action Buttons -->
        <!-- <btnSubmit
          v-if="cargando"
          :btnText="btnSubmit"
          :btnDisabled="$v.establecimiento.$invalid"
        /> -->

        <btnSubmit
          v-if="cargando"
          variant="primary"
          :btnText="btnSubmit"
          @processBtn="submitOption"
        />
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BForm, BOverlay, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { validationMixin } from 'vuelidate'
// import { mapActions, mapState } from 'vuex'

import btnSubmit from '../../components/Form/btnSubmit.vue'
import colLinea from '../../components/Form/colLinea.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    vSelect,
    BOverlay,
    BCard,

    btnSubmit,
    colLinea,
  },
  mixins: [validationMixin],
  props: {
    establecimiento: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Cambios',
    },
  },
  data() {
    return {
      cargando: true,
      // required,
      rolesOption: [],
    }
  },
  // validations: {
  //   establecimiento: {
  //     nombre: {
  //       required,
  //       maxLength: maxLength(200),
  //     },
  //   },
  // },
  methods: {
    submitOption() {
      this.$v.establecimiento.$touch()
      if (!this.$v.establecimiento.$invalid) {
        this.$emit('processForm', this.establecimiento)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
  .mt-1-alarma {
    margin-top: 9px;
  }
</style>
