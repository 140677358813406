<template>
  <div v-if="selectedEstablecimiento">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >

        <!-- General tab -->
        <b-tab active>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">General</span>
          </template>

          <establecimientosForm
            btnSubmit="Guardar Cambios"
            :establecimiento="selectedEstablecimiento"
            @processForm="editar"
          />
        </b-tab>
        <!--/ General tab -->

        <!-- Notificaciones tab -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="BellIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Notificaciones</span>
          </template>

          <notificaciones
            btnSubmit="Guardar Cambios"
            :establecimiento="selectedEstablecimiento"
            @processForm="editarNotificaciones"
          />
        </b-tab>
        <!--/ Notificaciones tab -->

      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>

import {
  // BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, BAvatar,
  BOverlay, BTabs, BTab,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import notificaciones from './configs/notificaciones.vue'
import establecimientosForm from './components/EstablecimientosForm.vue'

export default {
  components: {
    // BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, BAvatar,
    BOverlay,
    BTabs,
    BTab,

    notificaciones,
    establecimientosForm,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('establecimientos', ['selectedEstablecimiento']),
  },
  methods: {
    ...mapActions({ updateEstablecimiento: 'establecimientos/updateEstablecimiento' }),
    editar(establecimiento) {
      this.spinner = true
      this.updateEstablecimiento(establecimiento).then(() => {
        const errorEstablecimientos = store.state.establecimientos
        const errorMessage = errorEstablecimientos.errorMessage.errors
        if (!errorEstablecimientos.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Establecimiento editado 👍',
              text: `El establecimiento "${establecimiento.nombre}" fue editado con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          // this.$router.replace({
          //   name: 'establecimientos',
          // })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorEstablecimientos.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
    editarNotificaciones(establecimiento) {
      this.spinner = true
      this.updateEstablecimiento(establecimiento).then(() => {
        const errorEstablecimientos = store.state.establecimientos
        const errorMessage = errorEstablecimientos.errorMessage.errors
        if (!errorEstablecimientos.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Establecimiento editado 👍',
              text: `El establecimiento "${establecimiento.nombre}" fue editado con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          // this.$router.replace({
          //   name: 'establecimientos',
          // })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorEstablecimientos.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
